<template>
  <label class="custom-checkbox">
    <div class="checkbox-wrapper">
      <input type="checkbox" v-model="checked" @change="$emit('input', $event.target.checked)">
      <div class="bullet"></div>
    </div>
    <div class="text-desc">
      <slot></slot>
    </div>
  </label>
</template>

<script>
export default {
  name: "InputCheckbox",
  data() {
    return {
      checked: false,
    }
  },
  props: {},
}
</script>

<style lang="scss" scoped>
</style>