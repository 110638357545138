<template>
  <div class="details-list">
    <details class="content-block links-block">
      <summary>
        <div>
          <span>На сайте</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
              <path d="M6 15L12 9L18 15" stroke="white" stroke-linejoin="round"/>
            </g>
          </svg>
        </div>
      </summary>
      <div class="details-wrapper">
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>В эфире</span>
            <span>Когда на отслеживаемом турнире/матче начинается прямая трансляция</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Упоминания в чате</span>
            <span>Когда кто-то упоминает вас в чате</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>События</span>
            <span>Перед началом отслеживаемого события</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Действия друзей</span>
            <span>Когда друг отправляет вам личное сообщение</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Новый фолловер</span>
            <span>Когда вас начинает отслеживать другой пользователь SGL</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Подарки и награды</span>
            <span>Когда доступны особые предложения либо когда вы получаете подарочную подписку</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Маркетинг</span>
            <span>Маркетинговые сообщения и промоматериалы / рекомендации от SGL</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Рекомендованные турниры</span>
            <span>Когда проводятся турниры, которые мне могут понравиться, исходя из того, за какими я уже слежу или участвую</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Подписки</span>
            <span>Когда появляются новости о командах, игроках, турнирах или играх, на которые вы подписаны</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
      </div>
    </details>
    <details class="content-block links-block">
      <summary>
        <div>
          <span>По email</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
              <path d="M6 15L12 9L18 15" stroke="white" stroke-linejoin="round"/>
            </g>
          </svg>
        </div>
      </summary>
      <div class="details-wrapper">
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>В эфире</span>
            <span>Когда на отслеживаемом турнире/матче начинается прямая трансляция</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Упоминания в чате</span>
            <span>Когда кто-то упоминает вас в чате</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>События</span>
            <span>Перед началом отслеживаемого события</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Действия друзей</span>
            <span>Когда друг отправляет вам личное сообщение</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Новый фолловер</span>
            <span>Когда вас начинает отслеживать другой пользователь SGL</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Подарки и награды</span>
            <span>Когда доступны особые предложения либо когда вы получаете подарочную подписку</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Маркетинг</span>
            <span>Маркетинговые сообщения и промоматериалы / рекомендации от SGL</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Рекомендованные турниры</span>
            <span>Когда проводятся турниры, которые мне могут понравиться, исходя из того, за какими я уже слежу или участвую</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Подписки</span>
            <span>Когда появляются новости о командах, игроках, турнирах или играх, на которые вы подписаны</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
      </div>
    </details>
    <details class="content-block links-block">
      <summary>
        <div>
          <span>В приложении</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
              <path d="M6 15L12 9L18 15" stroke="white" stroke-linejoin="round"/>
            </g>
          </svg>
        </div>
      </summary>
      <div class="details-wrapper">
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>В эфире</span>
            <span>Когда на отслеживаемом турнире/матче начинается прямая трансляция</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Упоминания в чате</span>
            <span>Когда кто-то упоминает вас в чате</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>События</span>
            <span>Перед началом отслеживаемого события</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Действия друзей</span>
            <span>Когда друг отправляет вам личное сообщение</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Новый фолловер</span>
            <span>Когда вас начинает отслеживать другой пользователь SGL</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Подарки и награды</span>
            <span>Когда доступны особые предложения либо когда вы получаете подарочную подписку</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Маркетинг</span>
            <span>Маркетинговые сообщения и промоматериалы / рекомендации от SGL</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Рекомендованные турниры</span>
            <span>Когда проводятся турниры, которые мне могут понравиться, исходя из того, за какими я уже слежу или участвую</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
        <div class="content-block__string mb26 align-center">
          <div class="s1">
            <span>Подписки</span>
            <span>Когда появляются новости о командах, игроках, турнирах или играх, на которые вы подписаны</span>
          </div>
          <div class="s2">
            <input-checkbox></input-checkbox>
          </div>
        </div>
      </div>
    </details>
  </div>
</template>

<script>
import InputCheckbox from "@/components/UIElements/InputCheckbox";

export default {
  name: "profileNotifications",
  components: {InputCheckbox}
}
</script>

<style scoped lang="scss">
.details-list {
  summary {
    svg {
      margin-right: 50px;
    }
  }

  .content-block__string {
    justify-content: space-between;

    .s1 {
      flex-basis: 80%;
      margin-right: 0;
    }
  }

  @media (max-width: 580px) {
    summary {
      svg {
        margin-right: 0;
      }
    }
    .details-wrapper {
      padding: 17px 0;
      margin-top: 19px;
    }
  }
  @media (max-width: 420px) {
    .content-block.links-block {
      padding: 23px 16px;
    }
  }
}
</style>